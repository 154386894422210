import { useEffect, useState } from 'react';
import { Modal } from '#ui-kit';
import { Head, LoadingContent } from '#components';
import { HeadUnderlineVariant } from '#components.types';
import { removeEmptyFields } from '#utils';
import debug from '#services/debug.service';
import { OrdersService } from '#services/orders';
import { GetOrdersDto } from '#services/orders/dto/get-orders.dto';
import { OrdersType } from '#services/orders/responses/orders.response';
import { FilterForm } from './containers/FilterForm';
import Form from './containers/Table';

export default () => {
  const ordersService = new OrdersService();

  const [page, setPage] = useState<number>(1);
  const [size, setSize] = useState<number>(10);
  const [orders, setOrders] = useState<OrdersType>([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [filters, setFilters] = useState<Omit<GetOrdersDto, 'page' | 'size'>>({
    createdAtSort: 'desc',
  });

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const getOrders = async () => {
    setLoading(true);
    try {
      const response = await ordersService.getOrders({
        ...filters,
        page,
        size,
      });
      setOrders(response.data);
      setTotalPages(response.total);
    } catch (e) {
      debug.error('🧑🏻‍💻 ~ getOrders ~ err:', e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrders();
  }, [filters, page, size]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFilters((prevFilters) =>
      removeEmptyFields({
        ...prevFilters,
        [name]: value?.length ? value : undefined,
      }),
    );
  };

  const handleCheckboxGroupChange = (
    name: string,
    selectedValues: string[],
  ) => {
    setFilters((prevFilters) =>
      removeEmptyFields({
        ...prevFilters,
        [name]: selectedValues,
      }),
    );
  };

  const handleDeleteSelectedError = (name: string) => {
    setFilters((prevFilters) => {
      const filtered = prevFilters.errorMessageCodes?.filter(
        (error) => error !== name,
      );

      return removeEmptyFields({
        ...prevFilters,
        errorMessageCodes: filtered,
      });
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setPage(1);
    setSize(10);
    setFilters((prevFilters) => ({
      ...prevFilters,
      page,
      size,
    }));
  };

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setPage(pageNumber);
    setSize(pageSize);
  };

  return (
    <>
      <Head
        {...{
          label: 'Заказы',
          underlineVariant: HeadUnderlineVariant.Primary,
          button: {
            label: 'Фильтрация',
            onClick: handleOpenModal,
          },
        }}
      />
      {/* Форма фильтров */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <FilterForm
          handleCheckboxGroupChange={handleCheckboxGroupChange}
          handleFormSubmit={handleFormSubmit}
          handleInputChange={handleInputChange}
          handleDeleteSelectedError={handleDeleteSelectedError}
          filters={filters}
        />
      </Modal>
      {loading && page === 1 ? (
        <LoadingContent />
      ) : (
        <Form
          data={orders}
          loading={loading}
          currentPage={page}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
};
